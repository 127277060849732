@import 'computing-den-lib/src/client/base.css';

:root {
  --section-max-width-narrow: 1400px;
  --screenshot-background: #1f1f1f;
}

body {
}

#app {
}

.navbar.at-top {
  .logo-breadcrumb-separator,
  .breadcrumb {
    display: none;
  }
}

#intro {
  background: var(--screenshot-background);
  /* display: flex; */
  /* justify-content: center; */

  > .content {
    margin: 0 auto;
    padding: var(--section-vertical-padding) var(--section-horizontal-padding);
    padding-bottom: 2rem;
    max-width: var(--section-max-width-narrow);

    & h1.section-heading {
      font-size: 3rem;
      margin-bottom: 0;
      color: white;
    }
    & h3.slogan {
      margin-bottom: var(--section-heading-and-body-margin);
      text-align: center;
      color: #bbb;
      font-size: 1.5rem;
      line-height: 1.2;
    }

    #ytplayer {
      border-radius: 15px;
      width: 100%;
      aspect-ratio: 16 / 9;
      border: 5px solid var(--foreground-rgb);
      outline: 1px solid #ffffff4d;
    }

    .subscribe {
      justify-items: center;
      padding: 0 2rem;
    }

    @media (max-width: 600px) {
      margin-top: var(--navbar-height);
      padding: 0 0 2rem 0;

      #ytplayer {
        border-radius: unset;
        border: unset;
        box-shadow: unset;
        outline: unset;
      }
    }
  }
}

#slides {
  background: var(--section-bg-1);

  > .content {
    margin: 0 auto;
    padding: var(--section-vertical-padding) var(--section-horizontal-padding);
    max-width: var(--section-max-width-narrow);

    .slide {
      display: grid;

      grid-template-columns: 1fr auto;
      gap: 3rem;

      + .slide {
        margin-top: 3rem;
      }

      .desc {
        display: grid;
        align-content: center;

        & h2 {
          font-size: 3rem;
          line-height: 1.3;

          .step {
            font-size: 1.5rem;
            color: #888;
          }
        }

        & h2 + p {
          margin-top: 2rem;
        }
      }

      & img {
        height: 600px;
        border-radius: 10px;
        box-shadow: 0px 4px 15px 0px #00000061;
        /* border: 2px solid rgb(0 79 217); */
        background: var(--screenshot-background);
      }
    }

    @media (max-width: 1200px) {
      .slide {
        .desc {
          & h2 {
            font-size: 1.5rem;
            .step {
              font-size: 1rem;
            }
          }
          & h2 + p {
            margin-top: 1rem;
          }

          & p {
            font-size: 0.9rem;
          }
        }

        & img {
          height: 500px;
        }
      }
    }

    @media (max-width: 1200px) {
      .slide {
        grid-template-columns: auto;
        gap: 2rem;

        .desc {
          & h2 {
            text-align: center;
            font-size: 2rem;
            .step {
              font-size: 1rem;
            }
          }
          & h2 + p {
            margin-top: 2rem;
          }

          & p {
            font-size: 1rem;
          }
        }

        & img {
          width: 100%;
          height: unset;
        }
      }
    }
  }
}

#prose {
  --section-max-width-narrow: 1200px;
  > .content {
    margin: 0 auto;
    padding: var(--section-vertical-padding) var(--section-horizontal-padding);
    max-width: var(--section-max-width-narrow);

    & blockquote {
      padding: 0 1rem;
      /* background: #d0d9ff1a; */
      /* border-radius: 10px; */
      display: flex;
      border: 1px solid #e0e0e0;
      /* margin-left: 1rem; */
      /* margin-right: 1rem; */
      border-left: 4px solid #a5a5a5;
    }
  }
}

.subscribe {
  --color: var(--foreground-rgb);
  --background: white;
  --box-shadow: none;
  --border: 1px solid var(--foreground-rgb);
  --outline: none;
  --outline-hover: 1px solid var(--foreground-rgb);
  --success-color: var(--primary-color-hover-inverse);
  --error-color: #ff0000;

  &.for-intro {
    --color: white;
    --background: var(--section-bg-1);
    --box-shadow: 0px 4px 15px 0px #ffffff17;
    --border: 2px solid var(--foreground-rgb);
    --outline: 2px solid #ffffff4d;
    --outline-hover: 2px solid var(--primary-color);
    --success-color: var(--primary-color);
    --error-color: #ff0000;

    & p.message {
      text-align: center;
    }
  }

  margin-top: 2rem;
  display: grid;
  /* justify-content: center; */
  justify-items: start;
  gap: 1rem;
  color: var(--color);

  .input-container {
    position: relative;
    display: grid;
    justify-items: end;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    /* outline: 2px solid var(--primary-color); */
    border: var(--border);
    outline: var(--outline);
    background: var(--background);
    width: 100%;
    max-width: 600px;

    &:has(:hover),
    &:has(:active),
    &:has(:focus) {
      outline: var(--outline-hover);
    }

    @media (max-width: 600px) {
      border-width: 1px;
      outline: unset;
      /* border-radius: 10px; */
      /* max-width: unset; */
      /* width: calc(100vw - 2rem); */
    }

    & input {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      font-size: 1.5rem;
      border-radius: 0;
      outline: 0;
      border: 0;
      padding: 0.7rem 1.5rem;
      padding-right: 3rem;
      background: unset;
      width: 100%;
      /* color: ; */
      /* width: 600px; */
      /* max-width: 70vw; */

      @media (max-width: 800px) {
        font-size: 0.9rem;
        padding: 0.3rem 0.8rem;
        padding-right: 3rem;
      }
      @media (max-width: 600px) {
        max-width: unset;
        width: calc(100%);
      }
    }
    & button {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      /* position: absolute; */
      /* top: 0px; */
      background: unset;
      border: unset;
      outline: unset;
      /* padding: 0.4rem 1rem; */
      cursor: pointer;
      display: grid;
      align-items: center;
      justify-items: center;
      aspect-ratio: 1.2 / 1;
      /* margin-right: 0.5rem; */
      border-left: 2px solid transparent;
      height: 100%;
      min-width: 48px;

      @media (max-width: 800px) {
        /* padding: 0.1rem 1rem; */
      }

      /* &.left { */
      /*   left: 0.5rem; */
      /* } */
      /* &.right { */
      /*   right: 0; */
      /* } */

      &:active,
      &:focus {
        /* border-radius: 40px; */
      }

      &:hover,
      &:active,
      &:focus {
        border-color: var(--foreground-rgb);
        background: var(--section-bg-2);
        & svg {
          opacity: 1;
        }
      }

      & svg {
        stroke: var(--foreground-rgb);
        opacity: 0.8;
        width: 36px;
        height: 36px;
      }
    }
  }

  & p.message {
    margin: 0;
    /* width: 100%; */
    /* text-align: center; */
    /* font-style: italic; */

    /* & + p.message { */
    /*   margin-top: 1rem; */
    /* } */

    &.success {
      color: var(--success-color);
    }
    &.error {
      color: var(--error-color);
    }
  }
}
